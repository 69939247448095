/*
jQuery(function($){
	
	function resize($slider, i, callback){
	  // Necessary for this to operate as a callback for timing reasons
	  while (i < $slider.length) {
	    var _$slider = $($slider[i])
	    var _$slides = _$slider.children('.slides')
	    var _$slide  = _$slider.find('.slide')
	    var _l       = _$slide.length
	    var _i       = 1
	    var h        = [] // array of heights inside the slider
	    var h_largest
	    
	    while (_i < _l+1){
	      h[_i-1] = $(_$slide[_i-1]).outerHeight()  // Store all slide the heights
	      _i++
	    }
	    h_largest = Math.max.apply( Math, h) // Find the highest number
	    _$slides.height(h_largest) // Apply to height    
	    i++
	  }
	  callback(true)
	}

	function resizeSlider(){
	  var $slider = $('.lax-slider')
	  var $_next  = $slider.find('.next').addClass('disabled')
	  var $_prev  = $slider.find('.prev').addClass('disabled')
	  var $dot    = $slider.children('.dots').children().addClass('disabled')
	  var i       = 0
	  resize($slider, i, function(callback){
	    $_next.removeClass('disabled')
	    $_prev.removeClass('disabled')
	  }) 
	}
	$.fn.resize_laxslider = function(){
		waitForFinalEvent(function(){
		  resizeSlider()
		}, slideconfig.resizeInterval, "lax-slider")
	}

	function initSlider(callback){
	  var $slider = $('.lax-slider')
	  var i       = 0
	  
	  while (i < $slider.length) {
	    var _$slider = $($slider[i])
	    var _$slides = _$slider.children('.slides')
	    var _$slide  = _$slider.find('.slide')
	    var _l       = _$slide.length
	    var _i       = 1
	    var h        = [] // array of heights inside the slider
	    var h_largest
	    
	    _$slider.attr('data-slider-n', 1) // Assign the start value
	    while (_i < _l+1){
	      h[_i-1] = $(_$slide[_i-1]).outerHeight()  // Store all slide heights
	      _i++
	    }
	    h_largest = Math.max.apply( Math, h) // Find the highest number
	    _$slides.height(h_largest) // Apply to height
	    _$slide.first().addClass('active').css('opacity', 1)
	    $slider.find('.prev').hide()
	    i++
	  }
	  callback(true)
	}

	function changeSlide($nav){
		var $slider     = $nav.parents('.lax-slider')
		var $slide      = $slider.find('.slide')
		var l           = $slide.length
		var n           = parseInt($slider.attr('data-slider-n')) // Current .active
		var _n // Comparable current

		if ($nav.hasClass('next')) {
		  n += 1
		  if (n > l) {
		    n = 1
		  }
		}
		else if ($nav.hasClass('prev')) {
		  n -= 1
		  if (n < 1) {
		    n = l
		  }
		}
		
		// Update the slider
		$slider.attr('data-slider-n', n)

		// Proceed to animate the slider
		var $bg = $slider.parents('#lax-slider').find('.bg > div')
		var $slide_active = $slider.find('.slide.active')
		var slideWidth    = $slide_active.outerWidth()
		var $_next        = $slider.find('.next').addClass('disabled')
		var $_prev        = $slider.find('.prev').addClass('disabled')
		var $navTo        = $($slide[n-1])

		// Animate in the new direction
		switch (n) {
			case 1 :
				$_prev.hide()
				$bg.css('background-position', 'bottom left')
				break
			case 2 :
				$_prev.show()
				$_next.show()
				$bg.css('background-position', 'bottom')
				break
			case 3 :
				$_next.hide()
				$bg.css('background-position', 'bottom right')
				break
		}

		$slide_active.css('opacity', 0)
		setTimeout(function(){
		  $navTo.css('opacity', 1)
		}, 330)
		
		// Animation has finished
		setTimeout(function(){
		  $slide_active.removeClass('active')
		  $($slide[n-1]).addClass('active')
		  $_next.removeClass('disabled')
		  $_prev.removeClass('disabled')
		}, 660)
	}
	function binds(){
		var $next = $('.lax-slider .next')
		var $prev = $('.lax-slider .prev')
		$next.on('click', function(){
			var $this = $(this)
			if (!$this.hasClass('disabled')){
				changeSlide($this)
			}
		})
		$prev.on('click', function(){
			var $this = $(this)
			if (!$this.hasClass('disabled')){
				changeSlide($this)
			}
		})
	}
	function init(){
		initSlider(function(callback){
			binds() 	
		})
	}
	init()
})
jQuery(window).on('resize', function(){
	$.fn.resize_laxslider()
})
*/