var config = {
  'scrollSpeed'     : 800,
  'resizeInterval'  : 100
}

// timer
var waitForFinalEvent = (function(){
    // Self executing function to set a timer on window resize
    var timers = {}
    return function (callback, ms, uniqueId){
        if (!uniqueId) {
            uniqueId = "Don't call "+uniqueId+" twice!"
        }
        if (timers[uniqueId]) {
            clearTimeout (timers[uniqueId])
        }
        timers[uniqueId] = setTimeout(callback, ms)
    }  
})()

jQuery(function ($) {

    /* Global Variables */
    var windowWidth = $(window).width(),
        userAgent   = navigator.userAgent,
        iPhone      = userAgent.search('iPhone')    == -1 ? false : true,
        iPad        = userAgent.search('iPad')      == -1 ? false : true,
        android     = userAgent.search('Android')   == -1 ? false : true,
        isMobile    = false;

    if (iPhone || android || iPad){
        isMobile = true
    }

    var $body = $('body')
    var $header = $('header')
    var $profile = $('#profile')
    var $backToTop = $('.back-to-top')
    var $navMenuMobile = $('.nav-menu.mobile')
    var $navAccess = $('.nav-access')

    var $video = $('.video')
    var $galleryThumbnails = $('.gallery-thumbnail')
    var $galleryPreview = $('.gallery-preview')
    var $galleryThumbnailPreview = $('.preview')

    var pageScrollLast = 0

    // push scrolls
    var $bttn_profile = $('.bttn-profile')
    var $bttn_art = $('.bttn-art')
    var $bttn_properties = $('.bttn-properties')
    var $bttn_bio = $('.bttn-bio')
    var $bttn_testimonials = $('.bttn-testimonials')
    var $bttn_experience = $('.bttn-experience')
    var $bttn_contact = $('.bttn-contact')

    // scroll to position
    function scroll(ref) {
        var pos = $(ref).offset().top;
        $('html, body').animate({
            scrollTop: (pos)
        }, config.scrollSpeed)
    }
    
    // mobile menu header
    function menuDrawer(){
        // Open/close menu drawer
        if ($navAccess.hasClass('closed')){
            $navMenuMobile.css('height', '220px')
            $navAccess.addClass('opened').removeClass('closed')
            $navAccess.children('.menu').addClass('hidden')
            $navAccess.children('.close').removeClass('hidden')
        } else {
            $navMenuMobile.css('height', '0px')
            $navAccess.addClass('closed').removeClass('opened')
            $navAccess.children('.menu').removeClass('hidden')
            $navAccess.children('.close').addClass('hidden')
        }
    }

    // re-adjust header buffer depending if mobile or desktop
    function headerBuffer(){
        // push the top of the page down, based on the height of the header
        if ($navAccess.hasClass('closed')){
            $profile.css('margin-top', $header.outerHeight())
        }
    }

    function videoResize() {
        var height = ($video.width() / 16) * 9
        $video.height(height)
    }

    // gallery
    function thumbnailResize() {
        var height = ($($galleryThumbnails[1]).width() / 16) * 9
        $galleryThumbnails.height(height)
    }
    function thumbnailClick($thumbail){
        var _$gallery = $thumbail.parents('.gallery')
        var _$galleryThumbnails = _$gallery.find('.gallery-thumbnail')
        var _$galleryPreview = _$gallery.find('.gallery-preview')

        _$galleryPreview.css('background-image', $thumbail.css('background-image'))
        
        if (!$thumbail.hasClass('preview')){
            _$galleryThumbnails.removeClass('preview')
            $thumbail.addClass('preview') 
        }
    }

    function removeGalleryImage() {
        if (!$galleryThumbnailPreview.hasClass('hidden')) {
            $galleryThumbnailPreview.addClass('hidden')
        }
        
    }

    // window resize
    $(window).bind('resize', function(e) {
        waitForFinalEvent(function(){
            headerBuffer()
            thumbnailResize()
            videoResize()
        }, config.resizeInterval, "resize")
    })
     // window orientation
    $(window).bind('orientationchange', function(e) {
        waitForFinalEvent(function(){
            console.log('orientationchange')
            headerBuffer()
            thumbnailResize()
            videoResize()
        }, config.resizeInterval, "orientationchange")
    })
    // window scroll
    $(window).bind('scroll', function(e) {
        // window scroll
        if ($navAccess.hasClass('opened')){
            menuDrawer()
        }

        // slippery Header
        var direction
        if (window.pageYOffset == 0){
            $header.css('top', '0px')
        } else {
            if (window.pageYOffset  > pageScrollLast+10) {
                direction = 'down';
            } else if (window.pageYOffset <= pageScrollLast-10) {
                direction = 'up';
            }

            if (direction == 'down') {
                var height = $header.outerHeight()
                $header.css('top', '-'+height+'px')
            } else if (direction == "up") {
                $header.css('top', '0px')
            }
        }

        pageScrollLast = window.pageYOffset
    })
    

    // contact form
    var $input = $('input')
    var $input_firstname = $('#firstname')
    var $input_lastname = $('#lastname')
    var $input_email = $('#email')
    var $email_submit = $('.btn.submit')

    function isEmail(email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    }

    function isPhone(phone) {
      var regex = /([0-9+]{8,10})/;
      return regex.test(phone);
    }

    $input.on('keyup', function(e){
        if ($input_firstname.val() != "" &&
            $input_lastname.val() != "" &&
            $input_email.val() != ""){

            if (isEmail($input_email.val())) {
                if ($email_submit.hasClass('disabled')) {
                    $email_submit.removeClass('disabled')
                    $email_submit.unbind('click')
                } 
            }
            
        } else {
            if (!$email_submit.hasClass('disabled')) {
                $email_submit.addClass('disabled')
                bindSubmitClick()
            }
        }
    })

    function bindSubmitClick(){
        $email_submit.on('click', function(e){
            if (!$email_submit.hasClass('disabled')){
            } else {
                e.preventDefault()
            }
        })
    }

    // init
    function init(){
        headerBuffer()
        thumbnailResize()
        videoResize()

        $('.nav-access').on('click', function(){
            var $this = $(this)
            menuDrawer()
        })

        // gallery
        if (isMobile) {
            //removeGalleryImage()
        } else {
            $galleryThumbnails.on('click', function(){
                var $this = $(this)
                thumbnailClick($this)
            })
        }
        
         // back to top
        $('.back-to-top').on('click', function(){
            var $this = $(this)
            scroll("body")
        })

        bindSubmitClick()

        // push scrolls
        $bttn_profile.on('click', function(){
             scroll('#profile')
        })
        $bttn_art.on('click', function(){
             scroll('#art')
        })
        $bttn_bio.on('click', function(){
             scroll('#bio')
        })
        $bttn_testimonials.on('click', function(){
             scroll('#testimonials')
        })
        $bttn_experience.on('click', function(){
             scroll('#experience')
        })
        $bttn_contact.on('click', function(){
             scroll('#contact')
        })
    }

    init()
})