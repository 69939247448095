var slideconfig = {
  'logs'            : false,        // Want to see the console.logs?
  'resizeInterval'  : 200,          // Window resize timeout ms
  'slideSpeed'      : 330,          // Slide speed ms (match this in css transition speed)
  'min_height'      : 0,
  'looping'         : false
}

// Ready
jQuery(function ($) {
  
  function resize($slider, i, callback){
    // Necessary for this to operate as a callback for timing reasons
    while (i < $slider.length) {
      slideconfig.logs ? console.log('%c ( ⌐■_■) Resize Slider '+(i+1), ' background: #222; color: #bada55') : false
      var _$slider = $($slider[i])
      var _$slides = _$slider.children('.slides')
      var _$slide  = _$slider.find('.slide')
      var _l       = _$slide.length
      var _i       = 1
      var h        = [] // array of heights inside the slider
      var h_largest

      while (_i < _l+1){
        h[_i-1] = $(_$slide[_i-1]).outerHeight()  // Store all slide the heights
        _i++
      }
      h_largest = Math.max.apply( Math, h) // Find the highest number
      if (h_largest < slideconfig.min_height){
        _$slides.height(slideconfig.min_height) // Apply min height
      }
      else {
        _$slides.height(h_largest) // Apply to height
      }     
      i++
    }
    callback(true)
  }

  function resizeSlider(){
    var $slider = $('.slider')
    var $_next  = $slider.find('.next').addClass('disabled')
    var $_prev  = $slider.find('.prev').addClass('disabled')
    var $dot    = $slider.children('.dots').children().addClass('disabled')
    var i       = 0
    resize($slider, i, function(callback){
      $_next.removeClass('disabled')
      $_prev.removeClass('disabled')
      $dot.removeClass('disabled')
      slideconfig.logs ? console.log('%c ( ￣ ￣) . z Z ', 'background: #222; color: #bada55') : false
    }) 
  }
  $.fn.resize_slider = function(){
    waitForFinalEvent(function(){
      resizeSlider()
    }, slideconfig.resizeInterval, "slider")
  } 

  function initSlider(callback){
    var $slider = $('.slider')
    var i       = 0

    while (i < $slider.length) {
      slideconfig.logs ? console.log('%c ( ☉-☉) Init Slider '+(i+1), ' background: #222; color: #bada55') : false
      var _$slider = $($slider[i])
      var _$slides = _$slider.children('.slides')
      var _$slide  = _$slider.find('.slide')
      var $dots    = _$slider.find('.dots')
      var _l       = _$slide.length
      var _i       = 1
      var h        = [] // array of heights inside the slider
      var h_largest
      var col = $(_$slide[0]).attr('data-color')
      
      _$slider.attr('data-slider-n', 1) // Assign the start value
      
      // If there is a data-color
      if (col != undefined) {
        _$slider.parents('section').children('div').first().css('background-color', col)
      }

      while (_i < _l+1){
        h[_i-1] = $(_$slide[_i-1]).outerHeight()  // Store all slide heights
        $('<span/>', {
          'class'         : 'dot',
          'data-dot-n' : _i 
        }).appendTo($dots) // append a dot per slide
        _i++
      }

      h_largest = Math.max.apply( Math, h) // Find the highest number
      if (h_largest < slideconfig.min_height){
        _$slides.height(slideconfig.min_height) // Apply min height
      }
      else {
        _$slides.height(h_largest) // Apply to height
      }
      _$slider.find('.prev').hide()
      _$slider.find('.dot').first().addClass('active')
      _$slide.first().addClass('active').show()
      i++
    }
    slideconfig.logs ? console.log('%c ( ￣ ￣) . z Z ', 'background: #222; color: #bada55') : false
    callback(true)
  }

  function changeSlide($nav) {
    var $slider     = $nav.parents('.slider')
    var $slide      = $slider.find('.slide')
    var l           = $slide.length
    var n           = parseInt($slider.attr('data-slider-n')) // Current .active
    var _n // Comparable current
    var dir // Slide direction

    // Determine next slide n
    if ($nav.hasClass('next')) {
      dir = 'fwd'
      n += 1
      if (slideconfig.looping){
        if (n > l) {
          n = 1
          dir = 'bck'
        }
      }
    }
    else if ($nav.hasClass('prev')) {
      dir = 'bck'
      n -= 1
      if (slideconfig.looping){
        if (n < 1) {
          n = l
          dir = 'fwd'
        }
      }
      
    }
    else if ($nav.hasClass('dot')) {
      _n = parseInt($nav.attr('data-dot-n'))
      if (_n == n) {
        slideconfig.logs ? console.log('%c (ㆆ _ ㆆ) ', 'background: #222; color: #bada55') : false
        return false
      } 
      else if (_n > n){
        dir = 'fwd'
      }
      else if (_n < n){
        dir = 'bck'
      }
      n = _n
    }

    // Update the slider
    $slider.attr('data-slider-n', n)

    // Proceed to animate the slider
    var $slide_active = $slider.find('.slide.active')
    var $dot_active   = $slider.find('.dot.active')
    var $dot          = $slider.children('.dots').children().addClass('disabled')
    var slideWidth    = $slide_active.outerWidth()
    var $_next        = $slider.find('.next').addClass('disabled')
    var $_prev        = $slider.find('.prev').addClass('disabled')
    var $navTo        = $($slide[n-1])
    var col = $($navTo).attr('data-color')

    // Hide or show nav controls
    if (n >= l){
      $_next.hide()
      $_prev.show()
    }
    else if (n <= 1){
      $_next.show()
      $_prev.hide()
    }
    else {
      $_prev.show()
      $_next.show()
    }

    // Change bg-color if is a data-color
    if (col != undefined) {
      $slider.parents('section').children('div').first().css('background-color', col)
    }

    // Animate in the new direction
    if (dir == 'fwd'){
      $navTo.css('left', slideWidth)
      $slide_active.addClass('animate').css('left', -slideWidth)
      slideconfig.logs ? console.log('%c . . . ( ￣-￣) ', 'background: #222; color: #bada55') : false
    }
    else if (dir == 'bck') {
      $navTo.css('left', -slideWidth)
      $slide_active.addClass('animate').css('left', slideWidth)
      slideconfig.logs ? console.log('%c (￣-￣ ) . . . ', 'background: #222; color: #bada55') : false
    }
    $navTo.addClass('animate').show()

    setTimeout(function(){
      $navTo.css('left', '0px')
    }, 100)
    
    // Animation has finished
    setTimeout(function(){
      $slide_active.removeClass('active').hide()
      $dot_active.removeClass('active')
      $($slide[n-1]).addClass('active')
      $($dot[n-1]).addClass('active')
      $_next.removeClass('disabled')
      $_prev.removeClass('disabled')
      $dot.removeClass('disabled')
      $slide.removeClass('animate')
    }, slideconfig.slideSpeed)
  }

  function bindEvents() {    
    var $next = $('.slider .next')
    var $prev = $('.slider .prev')
    var $dot  = $('.slider .dot')

    $next.on('click', function(){
      var $this = $(this)
      if (!$this.hasClass('disabled')){
        changeSlide($this)
      }
    })
    $prev.on('click', function(){
      var $this = $(this)
      if (!$this.hasClass('disabled')){
        changeSlide($this)
      }
    })
    $dot.on('click', function(){
      var $this = $(this)
      if (!$this.hasClass('disabled')){
        changeSlide($this)
      }
    })
  }
  function init(){
    initSlider(function(callback){
      bindEvents()
    })
  }
  init()
})

// Window Resize
jQuery(window).on('resize', function(){
  $.fn.resize_slider()
})